/* eslint-disable */
import { ref } from '@vue/composition-api'
import axios from 'axios'
import qs from 'qs'
import Model from './model'


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}


export default class Client {


  constructor(options = {}) {

    this.axios = axios.create({
      baseURL: options.baseUrl,
    })

    this.axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' })

    this.delay = options.delay || 10

    this.isFinding = ref(false)
    this.isSaving = ref(false)
    this.isCreating = ref(false)
    this.isUpdating = ref(false)
    this.isPosting = ref(false)
    this.isPutting = ref(false)
    this.isGetting = ref(false)
    this.isDeleting = ref(false)
  
  }

  findModel(path, model, config = null) {
    this.isFinding.value = true
    return this.get(path, config).then(response => {
      model.clear().populate(response.data)
      return Promise.resolve(response)
    }).catch(error => {
      return Promise.reject(error)      
    }).finally(() => {
      this.isFinding.value = false
    })
  }

  createModel(path, model, names = null, config = null) {
    this.isCreating.value = true
    model.clearErrors()
    return sleep(this.delay).then(() => {
      return this.post(path, model.getAttributes(names), config).then(response => {
        model.populate(response.data, names)
        return Promise.resolve(response)
      }).catch(error => {
        if (error && error.response && error.response.status === 422) {
          model.setErrors(error.response.data.errors)
        }
        return Promise.reject(error)
      }).finally(() => {
        this.isCreating.value = false
      })
    })
  }

  updateModel(path, model, names = null, config = null) {
    this.isUpdating.value = true
    model.clearErrors()
    return sleep(this.delay).then(() => {
      return this.put(path, model.getDirtyAttributes(names), config).then(response => {
        model.populate(response.data, names)
        return Promise.resolve(response)
      }).catch(error => {
        if (error && error.response && error.response.status === 422) {
          model.setErrors(error.response.data.errors)
        }
        return Promise.reject(error)
      }).finally(() => {
        this.isUpdating.value = false
      })
    })
  }

  saveModel(path, model, names = null, config = null) {
    this.isSaving.value = true
    let isNewRecord = !Object.keys(model.getOldAttributes()).length
    return (isNewRecord ? this.createModel(path, model, names, config) : this.updateModel(path, model, names, config)).finally(() => {
      this.isSaving.value = false
    })
  }

  getModel(path, model, config = null) {
    this.isGetting.value = true
    return this.get(path, config).then(response => {
      model.clear().populate(response.data)
      return Promise.resolve(response)
    }).catch(error => {
      return Promise.reject(error)      
    }).finally(() => {
      this.isGetting.value = false
    })
  }

  postModel(path, model, names = null, config = null) {
    this.isPosting.value = true
    model.clearErrors()
    return sleep(this.delay).then(() => {
      return this.post(path, model.getAttributes(names), config).then(response => {
        return Promise.resolve(response)
      }).catch(error => {
        if (error && error.response && error.response.status === 422) {
          model.setErrors(error.response.data.errors)
        }
        return Promise.reject(error)
      }).finally(() => {
        this.isPosting.value = false
      })
    })
  }

  putModel(path, model, names = null, config = null) {
    this.isPutting.value = true
    model.clearErrors()
    return sleep(this.delay).then(() => {
      return this.put(path, model.getAttributes(names), config).then(response => {
        return Promise.resolve(response)
      }).catch(error => {
        if (error && error.response && error.response.status === 422) {
          model.setErrors(error.response.data.errors)
        }
        return Promise.reject(error)
      }).finally(() => {
        this.isPutting.value = false
      })
    })
  }

  get(path, config) {
    this.isGetting.value = true
    return this.axios.get(path, config).finally(() => this.isGetting.value = false)
  }

  post(path, data, config) {
    this.isPosting.value = true
    return this.axios.post(path, data, config).finally(() => this.isPosting.value = false)
  }

  put(path, data, config) {
    this.isPutting.value = true
    return this.axios.put(path, data, config).finally(() => this.isPutting.value = false)
  }

  delete(path, config) {
    this.isDeleting.value = true
    return this.axios.delete(path, config).finally(() => this.isDeleting.value = false)
  }


}
