<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    persistent
  >
    <v-card color="tertiary">
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title class="tertiary--text">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-8">

        <p>{{ message }}</p>

      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          depressed
          @click="hide()"
        >{{ closeText }}</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
/* eslint-disable */
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    value: Boolean,
    title: String,
    message: String,
    closeText: { type: String, default: 'Sluiten' },
  },
  setup(props, context) {

    const dialog = ref(props.value)

    watch(() => props.value, val => {
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    function show() {
      dialog.value = true
    }

    function hide() {
      dialog.value = false
    }

    return {
      dialog,

      show,
      hide,
    }

    
  },
}
</script>
