<template>
  <v-app :style="cssProps">

    <v-main class="secondary">
      <v-container>
        <v-card tile flat color="transparent" class="mb-5">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h4 primary--text font-weight-bold pa-0">Let's Develop</v-list-item-title>
              <v-list-item-title class="text-h1 primary--text font-weight-black line-height-80 pa-0 ml-n1">INCASSO</v-list-item-title>
              <v-list-item-title class="text-h1 primary--text font-weight-black line-height-80 pa-0 ml-n1">MACHTIGING.</v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="200"
            >
              <v-img
                contain
                class="clickable"
                @click="gotoHome()"
                src="@/assets/logo.svg">
              </v-img>
            </v-list-item-avatar>            
          </v-list-item>
        </v-card>
      </v-container>

      <v-container class="mb-2">
        <router-view></router-view>
      </v-container>

      <alert-dialog
        v-model="app.showError"
        title="Oeps! Er ging iets mis..."
        message="Helaas kunnen we op dit moment uw gegevens niet verwerken. Probeert u het later nog een keer. Mochten de problemen aanhouden, neem dan contact op met Let's Develop B.V."
      ></alert-dialog>

    </v-main>

    <v-overlay
      v-if="app.showLoader"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>

    <v-footer color="primary">
      <v-container class="mt-2 mb-1">
        <v-row no-gutters>
          <v-col cols="12" sm="6" lg="4">
            <v-card tile flat color="transparent">
              <v-list-item>
                <v-list-item-title class="text-h5 font-weight-medium secondary--text">Contact</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1 font-weight-regular white--text">Let's Develop B.V.</v-list-item-title>
                  <v-list-item-title class="text-body-1 font-weight-regular white--text">Maagdenburgstraat 24</v-list-item-title>
                  <v-list-item-title class="text-body-1 font-weight-regular white--text">7421 ZC Deventer</v-list-item-title>
                  <v-list-item-title class="text-body-1 font-weight-regular white--text">088 - 777 9500</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-card tile flat color="transparent">
              <v-list-item>
                <v-list-item-title class="text-h5 font-weight-medium secondary--text">Gegevens</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1 font-weight-regular white--text">KvK: 85304312</v-list-item-title>
                  <v-list-item-title class="text-body-1 font-weight-regular white--text">BTW nummer: NL863578627B01</v-list-item-title>
                  <v-list-item-title class="text-body-1 font-weight-regular white--text">IBAN: NL31RABO0378000640</v-list-item-title>
                  <v-list-item-title class="text-body-1 font-weight-regular white--text">BIC: RABONL2U</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
/* eslint-disable */
import { computed, reactive, provide, inject } from '@vue/composition-api'
import Client from '@/api/client'
import Model from '@/api/model'
import AlertDialog from '@/components/AlertDialog'

export default {
  components: {
    AlertDialog,
  },
  setup() {
    const vuetify = inject('vuetify')
    const router = inject('router')

    const app = reactive({ 
      showLoader: false,
      showError: false,
    })

    provide('app', app)

    const client = new Client({
      baseUrl: process.env.VUE_APP_API_URL,
    });

    provide('client', client)

    const directDebitModel = new Model({
      attributes: {
        direct_debit_request: null,
      },
      store: 'directDebitModel',
    })

    provide('directDebitModel', directDebitModel)

    const searchModel = new Model({
      attributes: {
        customer_postcode: null,
        customer_number: null,
      },
    })

    provide('searchModel', searchModel)

    const requestModel = new Model({
      attributes: {
        customer_postcode: null,
        customer_number: null,
        name: null,
        address: null,
        postcode: null,
        city: null,
        iban: null,
        iban_holder: null,
        email: null,
        signature: null,
        signature_place: null,
        is_requested: false,
      },
      store: 'requestModel',
    })

    provide('requestModel', requestModel)

    const rejectModel = new Model({
      attributes: {
        customer_postcode: null,
        customer_number: null,
        iban: null,
        direct_debit_stop_immediately: null,
        direct_debit_end_date: null,
        is_rejected: false,
      },
      store: 'rejectModel',
      // debug: true,
    })

    provide('rejectModel', rejectModel)

    const collectorModel = new Model({
      attributes: {
        name: null,
        address: null,
        postcode: null,
        city: null,
        id: null,
        reference: null,
        description: null,
      },
    })

    provide('collectorModel', collectorModel)

    client.getModel('direct-debit/collector', collectorModel)

    const cssProps = computed(() => {
      let themeColors = {}
      Object.keys(vuetify.theme.currentTheme).forEach(color => {
        themeColors[`--v-${color}`] = vuetify.theme.currentTheme[color]
      })
      return themeColors      
    })

    function gotoHome() {
      router.push({
        name: 'home',
      })
    }

    return {
      app,
      cssProps,
      gotoHome,
    }

  },
}
</script>



<style lang="scss">

.line-height-80 { line-height: 80% !important; }

.w-25 { width: 25%; }
.w-50 { width: 50%; }
.w-75 { width: 75%; }

.clickable {
  cursor: pointer;
}

</style>
