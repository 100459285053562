import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "direct-debit" */ '../views/DirectDebitView.vue'),
  },
  {
    path: '/machtiging/klantgegevens',
    name: 'direct-debit-customer',
    component: () => import(/* webpackChunkName: "direct-debit-customer" */ '../views/DirectDebitCustomerView.vue')
  },
  {
    path: '/machtiging/aanvraag',
    name: 'direct-debit-request',
    component: () => import(/* webpackChunkName: "direct-debit-request" */ '../views/DirectDebitRequestView.vue')
  },
  {
    path: '/machtiging/aangevraagd',
    name: 'direct-debit-requested',
    component: () => import(/* webpackChunkName: "direct-debit-requested" */ '../views/DirectDebitRequestedView.vue')
  },
  {
    path: '/machtiging/stopzetten',
    name: 'direct-debit-reject',
    component: () => import(/* webpackChunkName: "direct-debit-reject" */ '../views/DirectDebitRejectView.vue')
  },
  {
    path: '/machtiging/stopgezet',
    name: 'direct-debit-rejected',
    component: () => import(/* webpackChunkName: "direct-debit-rejected" */ '../views/DirectDebitRejectedView.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
