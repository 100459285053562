/* eslint-disable */


export class SessionStore {

  constructor(name) {
    this._name = name
  }

  getValue() {
    return sessionStorage.getItem(this._name)
  }

  getObjectValue() {
    let value = {}

    try {
        value = JSON.parse(this.getValue())
    } catch(err) {}

    return value;
  }
  
  setValue(value) {
    sessionStorage.setItem(this._name, value)
  }

  setObjectValue(value) {
    value = JSON.stringify(value)
    this.setValue(value)
  }

  removeValue() {
    return sessionStorage.removeItem(this._name)
  }

}