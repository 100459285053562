import { ref, computed, watch } from '@vue/composition-api'
import { SessionStore } from './session.store'

function isObject(x) {
  return x && typeof x === 'object' && x.constructor === Object
}



export default class Model {


  constructor(options = {}) {

    const store = options.store ? new SessionStore(options.store) : null
    const attributes = Object.assign({}, options.attributes)
    this.debug = options.debug || false

    if (store) {
      Object.assign(attributes, store.getObjectValue())
    }

    this.log('init', attributes)

    this.attributes = ref(attributes)
    this.oldAttributes = ref({})
    this.errors = ref({})
    this.defaultAttributes = Object.assign({}, options.attributes)
    this.rules = {}

    this.isNewRecord = computed(() => !Object.keys(this.oldAttributes.value).length)
    
    if (store) {
      watch(this.attributes, attributes => {
        this.log('store', Object.assign({}, attributes))
        store.setObjectValue(attributes)
      }, { deep: true })
    }

  }

  log() {
    this.debug && console.log(...arguments)
  }

  getAttribute(name) {
    return this.attributes.value[name]
  }

  setAttribute(name, value) {
    this.attributes.value = Object.assign({}, this.attributes.value, { [name]: value })
    return this
  }

  getAttributes(names = null) {

    names = Array.isArray(names)
      ? Object.keys(this.attributes.value).filter(name => names.includes(name))
      : Object.keys(this.attributes.value)

    const attributes = {}

    for (let name of names) {
      attributes[name] = this.getAttribute(name)
    }

    return attributes
  }

  setAttributes(attributes) {
    this.attributes.value = Object.assign({}, this.attributes.value, attributes)
    return this
  }

  getJsonAttribute(name, defaultValue = null) {
    try {
      return JSON.parse(this.getAttribute(name))
    } catch(err) {
      return defaultValue
    }
  }

  getOldAttribute(name) {
    return this.oldAttributes.value[name]
  }

  setOldAttribute(name, value) {
    this.oldAttributes.value = Object.assign({}, this.oldAttributes.value, { [name]: value })
    return this
  }

  setOldAttributes(attributes) {
    this.oldAttributes.value = Object.assign({}, this.oldAttributes.value, attributes)
    return this
  }

  getOldAttributes() {
    return this.oldAttributes.value
  }

  getError(name) {
    return this.errors.value[name]
  }

  getErrors() {
    return this.errors.value
  }

  clearErrors() {
    this.errors.value = {}
    return this
  }

  setError(name, messages) {
    this.errors.value = Object.assign({}, this.errors.value, { [name]: messages })
    return this
  }

  addError(name, message) {
    let messages = this.getError(name) || []
    messages.push(message)
    this.setError(name, messages)
    return this
  }

  setErrors(errors) {
    this.errors.value = errors
    return this
  }

  hasErrors() {
    return Object.keys(this.errors.value).length
  }

  hasError(name) {
    return this.errors.value[name] && this.errors.value[name].length
  }

  getDirtyAttributes(names = null) {

    names = Array.isArray(names)
      ? Object.keys(this.attributes.value).filter(name => names.includes(name))
      : Object.keys(this.attributes.value)

    const dirtyAttributes = {}

    for (let name of names) {
      if (this.getAttribute(name) !== this.getOldAttribute(name)) {
        dirtyAttributes[name] = this.getAttribute(name)
      }
    }

    return dirtyAttributes
  }

  // names: always overwrite names
  // overwriteNonDirty: overwrite non dirty attributes other than names
  populate(attributes, names = null, overwriteNonDirty = true) {

    if (names === null) {
      names = Object.keys(attributes)
    }

    const dirtyNames = Object.keys(this.getDirtyAttributes())

    const _attributes = {}
    const _oldAttributes = {}

    this.log('populate', attributes, names, overwriteNonDirty, dirtyNames)

    if (isObject(attributes)) {
      for (let name in attributes) {
        if (names.includes(name) || (overwriteNonDirty && !dirtyNames.includes(name))) {
          _attributes[name] = attributes[name]
        }
        _oldAttributes[name] = attributes[name]
      }
    }

    this.setOldAttributes(_oldAttributes)
    this.setAttributes(_attributes)
    return this
  }

  clear() {
    this.log('clear', Object.assign({}, this.defaultAttributes))
    this.attributes.value = Object.assign({}, this.defaultAttributes)
    this.oldAttributes.value = {}
    this.errors.value = {}
    return this
  }

  reset() {
    this.attributes.value = Object.assign({}, this.oldAttributes.value)
    return this
  }

  addRule(attribute, rule) {
    if (!this.rules[attribute]) {
      this.rules[attribute] = []
    }
    this.rules[attribute].push(rule)
  }

  validate() {
    this.clearErrors()
    const attributes = this.getAttributes()
    for (let attribute in attributes) {
      if (this.rules[attribute]) {
        for (let rule of this.rules[attribute]) {
          let message = rule(this.getAttribute(attribute), attributes)
          this.log('validate', attribute, message)
          if (message !== true) {
            this.addError(attribute, message)
          }
        }
      }
    }
    return !this.hasErrors()
  }

  clearRules() {
    this.rules = {}
  }


}




